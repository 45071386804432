import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import {
  Captions,
  Controls,
  Gesture,
  MediaLoadingStrategy,
  MediaPlayer,
  MediaProvider,
  TextTrack,
  Track,
  useMediaStore,
  type MediaPlayerInstance,
} from '@vidstack/react';
import { Transcript } from './transcript';
import { IcoPlay } from '@components/icons';
import * as Buttons from './buttons';
import * as Menus from './menus';
import * as Sliders from './sliders';
import { TimeGroup } from './time-group';
import { Spinner } from '@components/spinner';
import { isVideo } from 'shared/media';
import { useIntl } from 'shared/intl/use-intl';
import { router } from '@components/router';

export type Props = {
  id: UUID;
  url: string;
  poster?: string;
  captions?: string;
  downloadUrl?: string;
  ratio?: string;
  // Hides captions for students when true.
  hideCaptions?: boolean;
  type: string;
  load?: MediaLoadingStrategy;
  /**
   * If specified, this makes the player non-interactive and displays
   * the overlay in a little bubble (e.g. "Click 'student view' to view your video.")
   */
  overlay?: string;
};

// It's not possible to use the `useRouteParams` hook here
// because VidStack is rendered as a web component for the lesson content.
// So we are tracking the param changes by registering to the `onLocationChange`
// event  of the router.
function useFocusedFile() {
  const [focusedFile, setFocusedFile] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      id: params.get('focusedFile'),
      time: params.get('focusedTime'),
    };
  });

  useEffect(() => {
    const off = router.onLocationChange((params) => {
      setFocusedFile({
        id: params.focusedFile,
        time: params.focusedTime,
      });
    });

    return off;
  }, []);

  return focusedFile;
}

export function VidstackPlayer({
  id,
  url,
  poster,
  captions,
  hideCaptions,
  downloadUrl,
  type,
  overlay,
  load = 'play',
  ratio,
}: Props) {
  const intl = useIntl();
  const focusedFile = useFocusedFile();
  const isFocused = focusedFile?.id === id;

  const container = useRef<HTMLDivElement>(null);
  const playerType = isVideo(type) ? 'video' : 'audio';
  const player = useRef<MediaPlayerInstance>(null);
  const savedTime = useMemo(() => {
    // Vidstack stores time per url and clip times.
    // We are passing 0:0 because we don't use clips.
    const mediaId = `${url}:0:0`;
    return parseInt(localStorage.getItem(mediaId) || '0');
  }, []);

  const { height, canPlay, started: hasStarted } = useMediaStore(player);

  const [showPoster, setShowPoster] = useState(!!poster && playerType === 'video');
  const [showVolume, setShowVolume] = useState(false);
  const [loading, setLoading] = useState(false);
  const [track, setTrack] = useState<TextTrack | undefined>();
  const [showTranscript, setShowTranscript] = useState(isFocused);
  const [message, setMessage] = useState(intl('Loading...'));

  const showMessage = !canPlay && !showPoster && playerType === 'video';
  const displayCaptions = !!captions && !hideCaptions;
  const aspectRatioStyle = ratio ? `100 / ${ratio}` : '16/9';

  useEffect(() => {
    if (canPlay && loading && poster) {
      player.current?.play();
      setLoading(false);
      setShowPoster(false);
    }
  }, [canPlay, showPoster]);

  useEffect(() => {
    const anyPlayer = player.current as any;
    if (!player.current || anyPlayer?.$initialized) {
      return;
    }
    let timeout: any;
    anyPlayer.$initialized = true;

    function onTrackLoad(event: any) {
      const track = event.target as TextTrack;
      setTrack(track);
    }

    function onModeChange(event: any) {
      const track = event.detail as TextTrack;
      track.addEventListener('load', onTrackLoad);
    }

    if (playerType === 'video' && !showPoster) {
      player.current.startLoading();

      /**
       * If we have a bad or unsupported video, we don't seem to get any reliable
       * indication from vidstack-- no errors, no actionable status change. So
       * instead, we're picking a somewhat arbitrary timeout and displaying a
       * message if we hit it.
       */
      timeout = setTimeout(() => {
        if (player.current?.state.canPlay) {
          return;
        }
        // We may want to check for type application/x-mpegURL and handle that
        // specially, but for now, we'll show a general message.
        setMessage(
          intl(
            `This video is taking a while to load. If it is a recent video, it may not be available until after we finish processing it.`,
          ),
        );
      }, 3000);
    }

    player.current.textTracks.addEventListener('mode-change', onModeChange);

    return () => {
      anyPlayer.$initialized = false;
      player.current?.textTracks.removeEventListener('mode-change', onModeChange);
      track?.removeEventListener('load', onTrackLoad);
      clearTimeout(timeout);
    };
  }, [player.current]);

  // Scroll to the focused player
  useEffect(() => {
    if (isFocused && container.current) {
      container.current.scrollIntoView();
      setShowTranscript(true);
    }
  }, [isFocused, container.current]);

  useEffect(() => {
    if (player.current && isFocused && focusedFile.time) {
      player.current.remoteControl.seek(Number(focusedFile.time));
      player.current.remoteControl.play();
    }
  }, [isFocused, focusedFile, player.current]);

  return (
    <div ref={container} class={`max-w-(screen-16) ${playerType === 'video' ? 'min-h-32' : ''}`}>
      <MediaPlayer
        class={`text-white rounded-md ring-media-focus data-[focus]:ring-4 ${
          hasStarted ? '' : 'cursor-pointer'
        }`}
        crossOrigin="anonymous"
        ref={player}
        src={{ src: url, type: type as any }}
        // This persist the player state(captions, volume, quality) in the local storage
        // so it's shared across all the videos.
        // The last played section of the video is persisted per URL so it's not shared.
        storage="vidstack-player"
        load={isFocused ? 'visible' : load}
        autoPlay={isFocused}
        currentTime={focusedFile?.time ? Number(focusedFile.time) : undefined}
        aspectRatio={type === 'video' ? aspectRatioStyle : undefined}
        playsInline
        onClick={() => {
          // Both this and `onStarted` events are needed to start the video on click
          // on iOS Safari as it's weirdly not starting the video when poster is clicked.
          if (!hasStarted) {
            player.current?.startLoading();
          }
        }}
        onStarted={() => {
          // This is needed to start the video when the poster is clicked on iOS Safari.
          if (showPoster) {
            player.current?.remoteControl.play();
          }
        }}
        onPlay={() => {
          if (!player.current) {
            return;
          }
          // Start from the beginning if the saved time at the end of the video
          const duration = player.current.duration;
          if (savedTime + 1 > duration) {
            player.current.remoteControl.seek(0);
          }
        }}
      >
        <MediaProvider>
          {captions && (
            <Track src={captions} language="en-US" label="Transcript" kind="captions" default />
          )}
        </MediaProvider>
        {/* Use toggle:controls gesture on mobile and toggle:paused on desktop */}
        <Gesture
          class="absolute inset-0 z-0 block lg:hidden h-full w-full"
          event="pointerup"
          action="toggle:controls"
        />
        <Gesture
          class="absolute inset-0 z-0 hidden lg:block h-full w-full"
          event="pointerup"
          action="toggle:paused"
        />
        <Gesture
          class="absolute inset-0 z-0 block h-full w-full"
          event="dblpointerup"
          action="toggle:fullscreen"
        />
        <Gesture
          class="absolute left-0 top-0 z-10 block h-full w-1/5"
          event="dblpointerup"
          action="seek:-10"
        />
        <Gesture
          class="absolute right-0 top-0 z-10 block h-full w-1/5"
          event="dblpointerup"
          action="seek:10"
        />
        {displayCaptions && (
          <Captions
            class={`captions media-preview:opacity-0 media-controls:bottom-[85px] media-captions:opacity-100 absolute inset-0 bottom-2 z-10 select-none break-words opacity-0 transition-[opacity,bottom] duration-300`}
          />
        )}
        <Controls.Root
          class={`${
            playerType === 'video' ? 'absolute inset-0 z-10' : ''
          } flex h-full w-full flex-col`}
        >
          <div class="flex-1" />
          <div
            onMouseLeave={() => {
              setShowVolume(false);
            }}
            class={
              playerType === 'video'
                ? 'media-controls:opacity-100 opacity-0 transition-opacity bg-gradient-to-b from-transparent to-black/50 rounded-b-md'
                : 'border bg-gray-50 rounded-lg text-gray-600 dark:border-transparent dark:text-gray-200 dark:bg-gray-800'
            }
          >
            <Controls.Group class="flex w-full items-center px-2 !pointer-events-none media-controls:!pointer-events-auto">
              <Sliders.Time isVideo={playerType === 'video'} />
            </Controls.Group>
            <Controls.Group class="-mt-0.5 flex gap-1 w-full items-center px-2 pb-2 !pointer-events-none media-controls:!pointer-events-auto">
              <Buttons.Play />
              <Buttons.Mute onMouseOver={() => setShowVolume(true)} />
              <Sliders.Volume isVisible={showVolume} />
              <TimeGroup />
              <div class="flex-1" />
              {downloadUrl && <Buttons.Download downloadUrl={downloadUrl} />}
              {displayCaptions && <Buttons.Caption />}
              {displayCaptions && track && (
                <Buttons.Transcript
                  showTranscript={showTranscript}
                  setShowTranscript={setShowTranscript}
                />
              )}
              <Menus.Settings displayCaptions={displayCaptions} />
              {playerType === 'video' && (
                <>
                  <Buttons.PIP />
                  <Buttons.Fullscreen />
                </>
              )}
            </Controls.Group>
          </div>
        </Controls.Root>
      </MediaPlayer>

      {showMessage && (
        <div
          class="absolute z-10 inset-0 flex items-center justify-center text-gray-400 an-fade-in-slow px-4"
          key={message}
        >
          <div class="max-w-96 text-center text-sm">{message}</div>
        </div>
      )}

      {!canPlay && showPoster && (
        <div
          class="absolute z-10 inset-0 cursor-pointer"
          onClick={(e) => {
            if (loading) {
              return;
            }
            // Both this and `onStarted` events are needed to start the video on click
            // on iOS Safari as it's weirdly not starting the video when poster is clicked.
            if (!hasStarted) {
              player.current?.startLoading(e);
            }
            setLoading(true);
            if (canPlay) {
              setShowPoster(false);
            }
          }}
        >
          <img
            src={poster}
            alt="poster"
            class="poster-image object-cover w-full h-full pointer-events-none"
          />
          <div class="absolute inset-0 flex items-center justify-center">
            <button
              disabled={loading}
              class="flex items-center justify-center p-4 gap-2 rounded-full bg-gray-900/40 text-white hover:bg-gray-900/50 transition-all"
            >
              {!loading && (
                <>
                  <IcoPlay class="size-10" />
                  {savedTime > 0 && <span>{intl('Resume')}</span>}
                </>
              )}
              {loading && <Spinner />}
            </button>
          </div>
        </div>
      )}

      {!showMessage && overlay && (
        <div class="absolute z-10 inset-0 flex items-center justify-center text-sm">
          <div class="p-2 px-4 rounded-full text-white bg-black/50 backdrop-blur">{overlay}</div>
        </div>
      )}

      {showTranscript && track && (
        <Transcript
          fileId={id}
          track={track}
          height={height}
          jumpToTime={(time) => {
            player.current?.remoteControl.seek(time);
          }}
        />
      )}
    </div>
  );
}
